import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const AnnaDu = () => (
  <Layout title="Alumni Spotlight - Anna Du" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Anna Du</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Anna Du</h1>
            <h3 className="alumni__profile-type">
              2018 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Anna participated in the 2018 3M Young Scientist Challenge and
              continues to work on her innovation today. Now in 11<sup>th</sup>{' '}
              grade, Anna is extremely passionate about the environment and
              loves reading and astronomy.
            </h4>
            <h2 className="alumni__profile-question">
              Did you continue working on your 3M Young Scientist Challenge
              innovation? If yes, how far did you take your research and/or are
              you still working it, and do you have any progress updates that
              you can share?
            </h2>
            <p>
              Yes, even now, I’m still working on my 3M Young Scientist
              Challenge innovation! When I first attended the 3M Young Scientist
              Challenge, I designed a remotely operated vehicle to identify
              where microplastics were located on the ocean floor. Since then, I
              not only utilized artificial intelligence to enhance the
              microplastic detection system, but I was able to create a global
              map to predict likely areas of microplastic accumulation!
            </p>
            <h2 className="alumni__profile-question">
              What are your favorite three school subjects?
            </h2>
            <p>
              My three favorite subjects are astronomy research, college
              chemistry, and accelerated physics. Astronomy has always been a
              hobby of mine, and it’s very exciting for me to be able to explore
              what I’m passionate about during school-time. I also enjoy college
              chemistry and accelerated physics, which I’m taking simultaneously
              right now. It always surprises me how we can see the same concepts
              from so many different perspectives, and how that can drastically
              shift the way we understand the world!
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge? How would you say the 3M Young
              Scientist Challenge impacted your life?
            </h2>
            <p>
              A piece of advice I would give a student who is considering
              entering the 3M Young Scientist Challenge, is don’t be afraid to
              mess up. I believe that we are always going to make mistakes—it’s
              part of life. However, trial and error is an extremely important
              part of engineering, and I think that Thomas Edison’s quote, where
              he mentions that he has not failed, he’s just “found 10,000 ways
              that won’t work,” represents a crucial part of our lives.
              Attending the 3M Young Scientist Challenge has helped me change my
              approach to life.
            </p>
            <h2 className="alumni__profile-question">
              What is your favorite memory from your time as a 3M Young
              Scientist Challenge finalist?
            </h2>
            <p>
              My favorite memory is getting to tour the museum at 3M and walking
              through the history of some of the most popular products of the
              past century! I got to learn some awesome fun facts, which I still
              pull out as party tricks today, including the history behind the
              invention of the Post-it! I’ve always loved going to museums, and
              immersing myself in both the present, the past, and the potential
              for the future, and this was an experience that I’m certain I will
              never forget.
            </p>
            <h2 className="alumni__profile-question">
              Have you stayed in contact with your 3M mentor? Anything you’d
              like to share about your experience working with a 3M mentor?
            </h2>
            <p>
              I have stayed in contact with my 3M mentor, Ann Fornof! Connecting
              with her throughout my entire 3M experience was an amazing
              learning opportunity for me, especially as this was the first real
              science project I had conducted. During our weekly calls, I
              learned so much about how to design, conduct, and analyze
              experiments. After the competition, she even connected me to the
              organizers of the largest innovation conference in the Czech
              Republic — the Innovation Week, where I had the opportunity to
              give a keynote presentation!
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Anna-Du-Alumni-Quote.jpg" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “Attending the 3M Young Scientist Challenge has helped me
                  change my approach to life.”
                </h4>
                <h3>Anna Du</h3>
                <p>2018 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AnnaDu;
